$(function () {
  svg4everybody();
  
  // start 
  $('.js-start, .js-sound-play').on('click', function(){
    $(this).toggleClass('pause');
  });

  // tumbler
  $('.js-tumbler').on('click', function(){
    $(this).toggleClass('on');
    $('.js-sound-hidden').toggleClass('on');
  });

  // Setting
  $('.js-setting').on('click', function(){
    $('.js-setting-hidden').slideToggle();
  });

  // show all
  $('.js-show-all').on('click', function(){
    $(this).closest('.timers-item').find('.timers-item__content').addClass('open');
    $(this).fadeOut(0);
  });

  // стилизация селектов, чекбоксов и радиобаттонов
  $('select').styler({
    selectSmartPositioning: false,
    onSelectOpened: function(){
        $(this).find('.jq-selectbox__dropdown ul').scrollbar();
    }
  });

  // дополние к стилизации селектов
  // проверка на яблоки
  function IOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }
  // проверка на тач устроства
  function is_touch_device() {
    return !!('ontouchstart' in window);
  }
  if (IOS()) {
    $('.jq-selectbox select').on('change', function(){
      $(this).parent().removeClass('focused dropdown opened').css({'z-index':'0'}).find('.jq-selectbox__dropdown').fadeOut(0); 
    });
    $('.jq-selectbox').on('touchstart', function(){
      if ($(this).hasClass('opened')) {
        $(this).removeClass('focused dropdown opened').css({'z-index':'0'}).find('.jq-selectbox__dropdown').fadeOut(0);  
      } else {
        $('.jq-selectbox').removeClass('focused dropdown opened').css({'z-index':'0'}).find('.jq-selectbox__dropdown').fadeOut(0); 
        $(this).addClass('focused dropdown opened').css({'z-index':'100'}).find('.jq-selectbox__dropdown').fadeIn(0);
      }
    }); 
    $('.jq-selectbox li').on('touchstart', function(){
      $(this).closest('.jq-selectbox').removeClass('focused dropdown opened').css({'z-index':'0'}).find('.jq-selectbox__dropdown').fadeOut(0);
    });
  } 

  // gamb
  $('.js-gamb').on('click', function(){
    $(this).toggleClass('open');
    $('.js-gamb-hidden').slideToggle();
  });

  if($(window).width() < 768){
    $('.lang-header__title').on('click', function(){
      $('.lang-header__dropdown').slideToggle();
    });
  }

  // animation example
  $('.timer__bar span').css({'width':'0', 'transition-duration':'30s'});

});
